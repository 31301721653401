<template>
	<dd-card class="box-card" title="Detalii rulare" :collapsed.sync="isCollapsed" @toggledCard="toggledCard">
		<div class="sub-title" style="font-size:17px;color:red" v-if="hasRejected">
			<i class="el-icon-warning"> &nbsp;</i>Produse refuzate: <strong>{{data.runDetails.refused}}</strong>
		</div>
		<div class="sub-title" style="font-size:17px;color:green" v-if="hasUpdatedProducts">
			<i class="el-icon-success"></i> &nbsp;Produse acceptate: <strong>{{data.runDetails.updated}}</strong>
		</div>
		<div class="separator"></div>


		<div class="title">
			<strong>Probleme produse: </strong>
		</div>
		<el-table :data="logList" style="width: 100%;height:auto;max-height:500px;overflow-y: auto;" size="small">
			<el-table-column prop="rowNo" label="Linie"></el-table-column>

			<!--<el-table-column label="Cod produs" width="200px">
				<template slot-scope="scope">
					<strong>{{typeof scope.row.products_model === 'undefined' ? 'empty' : scope.row.products_model}}</strong>
				</template>
			</el-table-column>-->
			<!--<el-table-column :label="$t('publish.import.columns')" width="260px">
				<template slot-scope="scope">
					<ul>
						<template v-if="typeof scope.row.rowData !== 'undefined'">
							<li v-for="(item, index) in scope.row.rowData" :key="index">{{ index }}: {{ limitChs(item,100) }}</li>
						</template>
					</ul>
				</template>
			</el-table-column>-->

			<el-table-column prop="msg" :label="$t('publish.import.reason')">
				<template slot-scope="scope">
					<ul>
						<template v-if="typeof scope.row.errors !== 'undefined'">
							<li v-for="(item, index) in scope.row.errors" :key="index">{{item}}</li>
						</template>
					</ul>
				</template>
			</el-table-column>
		</el-table>
		<div class="center" v-if="hasRejected && limit < data.runData.products.length">
			<div class="separator"></div>
			<el-button type="primary" icon="el-icon-more" size="medium" plain @click.native="loadMore()">{{$t('sells.orders.load-more')}}</el-button>
		</div>
	</dd-card>
</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	const DDCard = () => import('@/components/DDCard');
	export default {
		name: 'feed-history-list',
		props: {
			data: {
				required: true,
				type: Object
			}
		},
		components: {
			'dd-card': DDCard,
			Box
		},
		data() {
			return {
				isCollapsed: true,
				historyData: {},
				limit: 50
			};
		},
		methods: {
			toggledCard(status) {
				this.isCollapsed = !this.isCollapsed;
			},
			loadMore() {
				if (this.limit + 50 <= this.data.runData.products.length) {
					this.limit += 50;
				} else {
					this.limit = this.data.runData.products.length;
				}
			},
			limitChs(txt, length) {

				if(typeof txt != 'string') {
					return txt;
				}

				return txt.substring(0, length)
			},
		},
		computed: {
			logList() {
				if (!this.hasRejected) return [];
				const list = [];
				const chunk = this.limit < this.data.runData.products.length ? this.limit : this.data.runData.products.length;
				for (let i = 0; i < chunk; i++) {
					list.push(this.data.runData.products[i]);
				}
				return list;
			},
			hasRejected() {
				return typeof this.data.runDetails !== 'undefined' && this.data.runDetails.refused > 0;
			},
			hasUpdatedProducts() {
				return typeof this.data.runDetails !== 'undefined' && this.data.runDetails.updated > 0;
			}
		},
		watch: {},
		created() {

		},
		mounted() {}
	};
</script>

<style lang="scss" scoped>
	.title {
		border: 0;
		margin-bottom: 0;
	}
</style>